<template>
    <div class="container-fluid">
        <div class="c-sidebar-tiny">
            <div class="c-sidebar-tiny__inner">
                <ul>
                    <router-link to="/setting" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-cogs"></i>
                                Cài đặt
                            </a>
                        </li>
                    </router-link>
                    <router-link to="/page" v-slot="{href, isActive}">
                        <li :class="[isActive ? 'active' : '']">
                            <a :href="href">
                                <i class="fa fa-pager"></i>
                                Ứng dụng
                            </a>
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
        <main class="c-app-content is-sidebar-tiny">
            <div class=""> <!-- c-container-inner -->
                <div class="row">
                    <div class="col-12" style="justify-content: space-around; flex-direction: row; display: flex;">
                        <div class="c-btn-mobile js-btn-mobile mt-2"><i class="fa fa-bars"></i> Mở rộng menu</div>
                    </div>
                    <div class="col-lg-2 c-sidebar-main">
                        <div class="c-sidebar-menu">
                            <ul>
                                <li v-if="$store.state.client && (permissions('client_update') || permissions('staff_index'))">
                                    <label>Đơn vị: {{$store.state.client.name}}</label>
                                    <ul>
                                        <router-link to="/page/profile/client" v-slot="{href, isExactActive}">
                                            <li v-if="$store.state.client && permissions('client_update')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Thông tin đơn vị</a></li>
                                        </router-link>
                                        <router-link to="/page/profile/staff" v-slot="{href, isExactActive}">
                                            <li v-if="$store.state.client && permissions('staff_index')" :class="[isExactActive ? 'active' : '']"><a :href="href"><i class="fa fa-angle-right"></i> Nhân viên</a></li>
                                        </router-link>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import $ from 'jquery';
    import role from '@/mixins/Role';
    export default {
        mixins: [role],
        data: function () {
            return {
                groups: [],
                config: []
            };
        },
        mounted: function () {
            this.config = this.$store.state.etc && this.$store.state.etc.site ? this.$store.state.etc.site : [];
            this.groups = [];
            Object.keys(this.$params.const).forEach(key => {
                let param = this.$params.const[key];
                if (this.config && ((!this.config.includes('svf') && param.group === 'SVF Platform') || (!this.config.includes('sog') && param.group === 'SOG Platform'))) {
                    return false;
                }
                if (this.groups.indexOf(param.group) === -1) {
                    this.groups.push(param.group);
                }
            });

            //js expand menu setting
            $('.js-btn-mobile').on('click', function (e) {
                e.preventDefault();
                var sidebar = $('.c-sidebar-main');
                if (sidebar.hasClass('active')) {
                    sidebar.removeClass('active').slideUp();
                } else {
                    sidebar.addClass('active').slideDown();
                }
            });

            //close setting menu in mobile
            $('.c-sidebar-menu a').on('click', function (e) {
                var sidebar = $('.c-sidebar-main');
                var viewportGlobal = $(window).width();
                if (e.target == this && viewportGlobal < 768) {
                    sidebar.removeClass('active').slideUp();
                }
            });
        }
    }
</script>